import React, { Children, cloneElement, useState } from 'react'
import classNames from 'classnames'

const ExpandAllContainer = ({ children, btnClass, linkId }) => {
  const [expandAll, setExpandAll] = useState(false)
  const [collapseAll, setCollapseAll] = useState(false)
  const reset = () => {
    setCollapseAll(false)
    setExpandAll(false)
  }

  return (
    <div className="expand-collapse-all">
      <div className="expand-collapse">
        <button
          id={linkId}
          className="expand-all"
          onClick={() => {
            setExpandAll(true)
            setCollapseAll(false)
          }}>Expand All
        </button>
        <button
          id={linkId}
          className={classNames("close-all", {'blue': btnClass === 'blue'})}
          onClick={() => {
            setCollapseAll(true)
            setExpandAll(false)
          }}>Collapse All
        </button>
      </div>
      {
        Children.map(children, (child, i) =>
          cloneElement(child, {
            key: i,
            collapseAll,
            expandAll,
            reset
          })
        )
      }
    </div>
  )
}

export default ExpandAllContainer